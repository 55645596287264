<template>
  <XSelectCombobox
    class="tag-select"
    label="Tags"
    :data-help="help"
    :disabled="disabled"
    :value="selectedTags"
    :items="tags"
    :check-chip-persistent="checkSystemTag"
    chips
    multiple
    dense
    is-object-in-return
    :item-text="(tag) => tag['name']"
    @input="(tag) => emitTags(tag)"
  />
</template>

<script>
import { defineComponent, ref, computed, onBeforeMount } from "vue";
import XSelectCombobox from "@/components/basic/XSelectCombobox.vue";
import { cockpitExplorerService } from "@/api";
import { checkSystemTag, ExplorerTag } from "@/composition/explorers/use-explorer-tags";


export default defineComponent({
  name: "ExplorerTagSelect",

  components: {
    XSelectCombobox
  },

  props: {
    help: String,
    value: Array,
    items: Array,
    disabled: {
      type: Boolean,
      default: false,
    }
  },

  setup(props, { emit }) {
    const tags = ref([]);
    const selectedTags = computed(() => {
      return props.value?.filter((tag) => tag.name.trim());
    });

    const emitTags = (newTags) => {
      const currentSystemTags = props.value?.filter(checkSystemTag) || [];

      const removedSystemTags = currentSystemTags.filter(systemTag => 
        !newTags.some(newTag => newTag.name === systemTag.name)
      );

      // new tags, that user just created are represented as string;
      const userTags = newTags.filter((t) => typeof t === "string").map(ExplorerTag);
      const objectTags = newTags.filter((t) => typeof t !== "string");

      const finalTags = [
        ...objectTags,
        ...removedSystemTags,
        ...userTags,
      ].map(tag => ({
        ...tag,
        name: tag.name.trim().replace(/\s+/g, "")
      }));

      emit("input", finalTags);
    };

    onBeforeMount(async () => {
      tags.value = await cockpitExplorerService.v2.getExplorersTags();
    });

    return {
      checkSystemTag,
      emitTags,
      tags,
      selectedTags,
    };
  },
});
</script>

<style lang="scss">
.tags-select {
  & .v-input__slot {
    min-height: 96px;
  }
}
</style>
